import React from "react"
import withPreview from "../components/context/withPreview"
import gql from "graphql-tag"
import Post from "../templates/Post/post"

const PreviewPost = (props) => {
  //Format data to match '/src/templates/single/Post' format
  const data = {
    post: props?.preview?.postBy?.revisions?.nodes[0], // grab the first revision
  }

  if (!!data.post) {
    return <Post data={data} />
  } else {
    return null
  }
}

const PREVIEW_QUERY = gql`
  query getPreview($id: Int!) {
    postBy(postId: $id) {
      revisions {
        nodes {
          title
          content
          date
          tags {
            nodes {
              databaseId
            }
          }
          featuredImage {
            node {
              altText
              srcSet
            }
          }
          acfRecipe {
            difficulty
            expiration
            freezing
            introduction
            poulainstouch
            authorship {
              name
              role
            }
            cookingtime {
              hours
              minutes
            }
            ingredients {
              issubtitle
              description
              fraction
              precisequantity
              preciseunity
              quantity
              steps
              unity
            }
            maintag {
              name
              id
            }
            servings {
              label
              value
            }
            servingsvariations {
              multiplier
              value
            }
            steps {
              description
              image {
                altText
                srcSet
              }
            }
            totaltime {
              hours
              minutes
            }
            gallery {
              altText
              srcSet
            }
          }
        }
      }
    }
  }
`

export default withPreview({ preview: PREVIEW_QUERY })(PreviewPost)
